import * as React from 'react';
import Api from 'api/Api';
import { SettingsModel } from 'api/models/general';

import { CancelablePromise } from 'api/services/_BaseApi';
import { throwNetworkError } from 'utils/requestCancelation';

import { getTimezoneName } from 'utils/formatDate';

export const GlobalContext = React.createContext({} as any);

const apiRequests: {
    getTranslationList: CancelablePromise<Record<string, string>>;
} = {
    getTranslationList: null,
};

export let globalSettings,
    setGlobalSettings,
    translations,
    setTranslations,
    isCommonMenuOpen,
    setIsCommonMenuOpen;

export const createTranslationProxy = (translations: Record<string, string>) => new Proxy({ ...translations }, {
    get(target, phrase: string) {
        if (phrase in target) {
            return target[phrase];
        } else {
            if (phrase !== 'undefined' && phrase !== '') {
                console.warn(`Missing translation for <${phrase.toString()}>`);
            }

            return phrase;
        }
    }
});

export const updateTranslationsList = async () => {
    apiRequests.getTranslationList = Api.General.GetTranslationList();

    try {
        const updatedTranslations = await apiRequests.getTranslationList;

        setTranslations(createTranslationProxy(updatedTranslations || {}));
    } catch (error) {
        throwNetworkError(error);
    }
};

export const GlobalContextProvider = (props) => {
    [globalSettings, setGlobalSettings] = React.useState<SettingsModel>({} as SettingsModel);
    [translations, setTranslations] = React.useState({});
    [isCommonMenuOpen, setIsCommonMenuOpen] = React.useState(true);

    const checkPermission = (name: string): boolean => {
        return Boolean(globalSettings?.user?.Permissions?.includes(name));
    };

    const permissions = {
        dashboardPermission: checkPermission('dashboard:view'),
        eposPermission: checkPermission('health-monitor:view'),
        tilesViewPermission: checkPermission('health-monitor:view-tiles'),
        technicianPagePermission: checkPermission('health-monitor:view-technician-page'),
        restartTerminalPermission: checkPermission('health-monitor:restart-terminal'),
        restartShellPermission: checkPermission('health-monitor:restart-shell'),
        restartPrinterPermission: checkPermission('health-monitor:restart-printer'),
        reinitBillValPermission: checkPermission('health-monitor:reinit-bill-validator'),
        sshPermission: checkPermission('health-monitor:ssh-access'),
        vncPermission: checkPermission('health-monitor:vnc-access'),
        maintenancePermission: checkPermission('health-monitor:maintenance-mode'),
        manageMaintenanceModeImagesPermission: checkPermission('health-monitor:manage-maintenance-mode-images'),
        updateShellPermission: checkPermission('health-monitor:update-shell'),
        reloadShellSettingsPermission: checkPermission('health-monitor:reload-shell-settings'),
        switchEnvironment: checkPermission('health-monitor:switch-environment'),
        rolesViewPermission: checkPermission('roles:view'),
        rolesAddPermission: checkPermission('roles:add'),
        rolesEditPermission: checkPermission('roles:edit'),
        usersViewPermission: checkPermission('users:view'),
        usersAddPermission: checkPermission('users:add'),
        usersEditPermission: checkPermission('users:edit'),
        usersExportPermission: checkPermission('users:export'),
        usersBlockUnblockPermission: checkPermission('users:block-unblock'),
        usersResetPasswordPermission: checkPermission('users:reset-password'),
        userResetSupervisorBarcodePermission: checkPermission('users:reset-supervisor-barcode'),
        userResetSupervisorPinPermission: checkPermission('users:reset-supervisor-pin'),
        userChangeBetshopPermission: checkPermission('users:change-betshop'),
        downloadSupervisorBarcodePermission: checkPermission('users:download-supervisor-barcode'),
        auditLogsPermission: checkPermission('audit-logs:view'),
        networkManagementBetshopsView: checkPermission('network-management-betshops:view'),
        networkManagementBetshopsAdd: checkPermission('network-management-betshops:create'),
        networkManagementBetshopsEdit: checkPermission('network-management-betshops:edit'),
        networkManagementBetshopsSetTest: checkPermission('network-management-betshops:set-test'),
        reportTicketsView: checkPermission('tickets-report:view'),
        reportTicketsExportPermission: checkPermission('tickets-report:export'),
        reportTicketsAuthorizeReprint: checkPermission('tickets-report:authorize-reprint'),
        reportTicketsTicketVoid: checkPermission('tickets-report:cancel-ticket'),
        reportSupervisorView: checkPermission('supervisor-audit-report:view'),
        reportSupervisorExportPermission: checkPermission('supervisor-audit-report:export'),
        reportCardTransactionsView: checkPermission('card-payment-transactions-report:view'),
        reportCardTransactionsExportPermission: checkPermission('card-payment-transactions-report:export'),
        reportFinancialPerformanceView: checkPermission('financial-performance-report:view'),
        reportVouchersView: checkPermission('vouchers-report:view'),
        reportVouchersExportPermission: checkPermission('vouchers-report:export'),
        reportVouchersAuthorizeReprint: checkPermission('vouchers-report:authorize-reprint'),
        reportPlayerStatementView: checkPermission('player-statement-report:view'),
        oddsSheetsPermission: checkPermission('odds-sheets-pdf-report:view'),
    };

    const timeZone = getTimezoneName(globalSettings?.settings?.TimeZoneIANAName);

    React.useEffect(() => {
        (async () => {
            try {
                const result = await Api.General.GetGlobalSettings();

                setGlobalSettings(result);
                setTranslations(createTranslationProxy(result.translations || {}));
            } catch (error) {
                throwNetworkError(error);
            }
        })();
    }, []);

    return (
        <GlobalContext.Provider
            value={{
                globalSettings,
                translations,
                isCommonMenuOpen,
                dateTimeFormat: `${globalSettings?.settings?.DateFormat} ${globalSettings?.settings?.TimeFormat}`,
                timeZone,
                permissions
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};
